import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router'

import marked from 'marked';
import dateformat from 'dateformat'
import ImageZoom from 'react-medium-image-zoom'

const PostItem = (props) => {
  var {post} = props;

  if (post === void 0) {
    const {year, month, slug} = props.params;
    post = props.posts.find(p => p.url_slug === `/${year}/${month}/${slug}`);
  }

  const date = dateformat(new Date(post.date), 'mmmm dS, yyyy')

  return (
    <article>
      <header>
        <h1>
          <Link to={post.url_slug}>{post.title}</Link>
        </h1>
        <p>{date}</p>
      </header>

      {post.content ? (
        <section dangerouslySetInnerHTML={{ __html: marked(post.content) }}/>
      ) : (
        <section>
          <ImageZoom
            image={{
              src: post.instagramUrl,
              alt: post.title,
              className: 'img',
              style: { width: '50em' }
            }}
            zoomImage={{
              src: post.instagramUrl,
              alt: post.title
            }}
          />
        </section>
      )}
    </article>
  );
};

function mapStateToProps(state) {
  return {
    posts: state.posts
  };
}

export default connect(mapStateToProps)(PostItem);
