import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router'

import marked from 'marked';

const Side = (props) => {
  const {settings} = props;

  return (
    <div className="react-sidebar">
      <Link to="/" className="avatar" alt="Avatar" aria-label="Avatar">
        <img src={settings.gravatar} width="96" alt="" />
      </Link>
      <h1 className="name">
        <Link to="/">Søren Brokær</Link>
      </h1>
      <span className="tagline"
            dangerouslySetInnerHTML={{ __html: marked(settings.bio || '') }}/>
      <ul>
        <li>
          <a href="https://github.com/srn" target="_blank" rel="noopener">github</a>
        </li>
        <li>
          <a href="https://twitter.com/soerenr" target="_blank" rel="noopener">twitter</a>
        </li>
        <li>
          <a href="https://linkedin.com/in/soerenr" target="_blank" rel="noopener">linkedin</a>
        </li>
        <li>
          <a className="email" href="mailto:%72%6f%6f%74@%73%72%6e.%69%6f" target="_blank">email</a>
        </li>
      </ul>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    settings: state.settings
  };
}

export default connect(mapStateToProps)(Side);
