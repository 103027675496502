import 'normalize.css'

import React from 'react';
import { hydrate } from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import ga from 'ga-react-router'

import { configureStore } from '../shared/redux/store/configureStore';

import routes from '../shared/routes';
const store = configureStore(window.__INITIAL_STATE__);

const node = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
  browserHistory.listen(location => {
    ga('set', 'page', location.pathname);
    ga('send', 'pageview');
  });
}

let element = (
  <Provider store={store}>
    <Router history={browserHistory} routes={routes} />
  </Provider>
)

hydrate(element, node)
